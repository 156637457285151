<template>
    <div class="searchResult">
        <headComp></headComp>
        <breadComp></breadComp>

        <div class="centerWrapper">
            <div class="searchNum">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item>全部结果</el-breadcrumb-item>
                    <el-breadcrumb-item>“芬洛”（112）</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="resultBrand">
                <div class="item">
                    <img src="../assets/imgs/place37.png"/>
                </div>
            </div>
            <!-- <div class="interspace">
                <div class="space">
                    <el-select v-model="spaceIndex">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="classify">
                    <div :class="{'item':1,'active':i==1}" v-for="i in 6" :key="i">沙发</div>
                </div>
            </div> -->
            <div class="kinds">
                <div class="line">
                    <div class="kindName">商品分类</div>
                    <div class="subs">
                        <div :class="{'item':1,'active':i==1}" v-for="i in 2" :key="i">沙发</div>
                    </div>
                    <div class="opt" v-if="false">
                        <el-button class="more" type="text">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                        <el-button class="multi" plain round icon="el-icon-plus">多选</el-button>
                    </div>
                </div>
                <div class="line">
                    <div class="kindName">商品风格</div>
                    <div class="subs">
                        <div :class="{'item':1,'active':i==1}" v-for="i in 3" :key="i">新中式</div>
                    </div>
                    <div class="opt" v-if="false">
                        <el-button class="more" type="text">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                        <el-button class="multi" plain round icon="el-icon-plus">多选</el-button>
                    </div>
                </div>
            </div>
            <div class="filtrate">
                <div class="condition">
                    <div class="dimen">
                        <el-button type="text" class="active">综合</el-button>
                        <el-button type="text">销量<i class="el-icon-d-caret el-icon--right"></i></el-button>
                        <el-button type="text">最新<i class="el-icon-d-caret el-icon--right"></i></el-button>
                        <el-button type="text">价格<i class="el-icon-d-caret el-icon--right"></i></el-button>
                    </div>
                    <div class="priceRange">
                        <span>价格</span>
                        <div class="price">
                            ￥<input/>
                        </div>
                        <span class="gap"></span>
                        <div class="price">
                            ￥<input/>
                        </div>
                    </div>
                    <div class="sendTo">
                        <span>送至</span>
                        <el-select v-model="sendToArea" placeholder="请选择地区">
                            <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="view">
                    <span class="total">共117件商品</span>
                    <span class="ratio">1/4</span>
                    <div class="swi">
                        <el-button type="text"><i class="el-icon-arrow-left el-icon--right"></i></el-button>
                        <el-button type="text"><i class="el-icon-arrow-right el-icon--right"></i></el-button>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="item" v-for="i in 12" :key="i">
                    <img src="../assets/imgs/place11.png"/>
                    <div class="price"><span>￥</span>4099.00</div>
                    <div class="intro">
                        <span class="tag">【帕帕猫】</span>
                        简美风格  健康环保  质地坚硬  橡胶木  金丝檀木  纯  实木  儿童床 浅胡桃色  1.2*1.9米子母床（搭配桐木床） 
                    </div>
                    <div class="rela">
                        <div>
                            <img src="../assets/imgs/solded.png"/>
                            <span class="num">28</span>
                            <span>已售</span>
                        </div>
                        <div>
                            <img src="../assets/imgs/heat.png"/>
                            <span class="num">997</span>
                            <span>热度</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagi">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage4"
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="12"
                    layout="prev, pager, next, jumper"
                    :total="400">
                </el-pagination>
            </div>
        </div>

        <footerComp></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
export default {
    components: {
        headComp,footerComp,breadComp
    },
    data(){
        return{
            currentPage4:1,
            spaceIndex:1,
            options:[
                {
                    value: 1,
                    label: '客厅'
                }, {
                    value: 2,
                    label: '厨房'
                }
            ],
            sendToArea:'',
            options2:[
                {
                    value: 1,
                    label: '上海'
                }, {
                    value: 2,
                    label: '北京'
                }
            ],
        }
    },
    methods: {
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
      }
    },
}
</script>

<style lang="less" scoped>
.searchResult{
    .searchNum{
        margin-top: 50px;
        /deep/.el-breadcrumb__inner{
            font-size: 20px;
            font-weight: 400;
            color: rgba(0, 0, 0, 1);
        }
    }
    .resultBrand{
        margin-top: 20px;
        display: flex;
        align-items: center;
        .item{
            width: 160px;
            height: 80px;
            margin-right: 20px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .interspace{
        margin-top: 50px;
        background: rgba(245, 245, 245, 1);
        height: 41px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        color: rgba(128, 128, 128, 1);
        padding: 0 22px;
        .space{
            width: 121px;
            height: 100%;
            box-sizing: border-box;
            .el-select{
                width: fit-content;
                /deep/.el-input{
                    width: fit-content;
                }
                /deep/.el-input__inner{
                    width: 60px;
                    padding: 0;
                    border: none;
                    background: transparent;
                    font-size: 14px;
                    font-weight: 700;
                    color: rgba(128, 128, 128, 1);
                }
            }
        }
        .classify{
            flex: 1;
            height: 100%;;
            display: flex;
            align-items: center;
            .item{
                padding: 0 10px;
                height: 25px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 700;
                color: rgba(128, 128, 128, 1);
                margin-right: 20px;
                &:last-child{
                    margin-right: 0;
                }
                &.active{
                    background: rgba(37, 126, 93, 1);
                    color: rgba(255, 255, 255, 1);
                    font-weight: 400;
                }
            }
        }
    }
    .kinds{
        margin-top: 40px;
        .line{
            padding: 20px 0;
            border-bottom: 1px solid rgba(237, 237, 237, 1);
            display: flex;
            align-items: center;
            .kindName{
                width: 121px;
                box-sizing: border-box;
                font-size: 18px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1)
            }
            &:last-child{
                // border-bottom: none;
            }
            .subs{
                flex: 1;
                height: 100%;;
                display: flex;
                align-items: center;
                .item{
                    padding: 0 10px;
                    height: 39px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 400;
                    color: rgba(128, 128, 128, 1);
                    margin-right: 20px;
                    &:last-child{
                        margin-right: 0;
                    }
                    &.active{
                        background: rgba(37, 126, 93, 1);
                        color: rgba(255, 255, 255, 1);
                        font-weight: 400;
                    }
                }
            }
            .opt{
                margin-left: 20px;
                .el-button.more{
                    width: 54px;
                    height: 20px;
                    padding: 0;
                    font-size: 13px;
                    font-weight: 400;
                    color: rgba(128, 128, 128, 1);
                    border:none;
                }
                .el-button.multi{
                    width: 54px;
                    height: 20px;
                    padding: 0;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(56, 56, 56, 1);
                }
            }
        }
    }
    .filtrate{
        margin-top: 40px;
        height: 41px;
        padding: 6px 22px;
        box-sizing: border-box;
        background: rgba(245, 245, 245, 1);
        display: flex;
        justify-content: space-between;
        .condition{
            height: 28px;
            display: flex;
            align-items: center;
            .dimen{
                width: 248px;
                height: 100%;
                border: 1px solid rgba(255, 255, 255, 1);
                .el-button{
                    width: 25%;
                    height: 100%;
                    margin: 0;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                    border-radius: 0;
                    padding: 0;
                    &.active{
                        background: #B83030;
                        color: #fff;
                    }
                }
            }
            .priceRange{
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                margin-left: 30px;
                .price{
                    width: 60px;
                    height: 100%;
                    background: rgba(255, 255, 255, 1);
                    border: 1px solid rgba(237, 237, 237, 1);
                    display: flex;
                    align-items: center;
                    padding: 0 5px;
                    box-sizing: border-box;
                    input{
                        text-align: right;
                        width: calc(100% - 10px);
                        outline: none;
                        border: none;
                        background: transparent;
                    }
                }
                .gap{
                    height: 1px;
                    background: #000;
                    width: 8px;
                    margin: 0 10px;
                }
                &>span:nth-child(1){
                    margin-right: 5px
                }
            }
            .sendTo{
                margin-left: 30px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                .el-select{
                    height: 28px;
                    width: 137px;
                    margin-left: 5px;
                    /deep/.el-input__inner{
                        height: 28px;
                        line-height: 28px;
                        border-radius: 0;
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(102, 102, 102, 1);
                    }
                    /deep/.el-input__icon{
                        line-height: 28px;
                    }
                }
            }
        }
        .view{
            display: flex;
            align-items: center;
            .total{
                font-size: 12px;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
            }
            .ratio{
                font-size: 12px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
                margin: 0 22px;
            }
            .swi{
                width: 90px;
                height: 100%;
                border: 1px solid rgba(255, 255, 255, 1);
                .el-button{
                    width: 50%;
                    height: 100%;
                    margin: 0;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                    border-radius: 0;
                    padding: 0;
                    &.active{
                        background: #B83030;
                        color: #fff;
                    }
                }
            }
        }
    }
    .container{
        // margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        .item{
            width: 337px;
            margin-top: 40px;
            margin-right: 40px;
            &:nth-child(4n){
                margin-right: 0;
            }
            &>img{
                width: 100%;
                height: 252px;
            }
            .price{
                font-size: 22px;
                font-weight: 700;
                color: rgba(200, 64, 57, 1);
                margin-top: 13px;
                &>span{
                    font-size: 14px
                }
            }
            .intro{
                font-size: 13px;
                font-weight: 700;
                letter-spacing: -0.7px;
                line-height: 20px;
                color: rgba(56, 56, 56, 1);
                margin-top: 6px;
                height: 40px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp:2;
                line-clamp:2;
                .tag{
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 20px;
                    color: rgba(158, 110, 62, 1);
                }
            }
            .rela{
                display: flex;
                align-items: center;
                margin-top: 10px;
                &>div{
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    font-weight: 700;
                    color: rgba(153, 153, 153, 1);
                    margin-right: 15px;
                    padding-right: 15px;
                    border-right: 1px solid rgba(237, 237, 237, 1);
                    &:last-child{
                        border-right: none;
                        margin-right: 0;
                    }
                    .num{
                        font-size: 12px;
                        font-weight: 700;
                        color: rgba(230, 77, 0, 1);
                        margin: 0 4px;
                    }
                }
            }
        }
    }
    .pagi{
        margin-top: 130px;
        text-align: center;
        /deep/.btn-next , /deep/.btn-prev{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
        }
        /deep/ .number , /deep/.el-icon-more, /deep/.btn-quickprev, /deep/.btn-quicknext{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
            &.active{
                background-color: rgba(35, 125, 92, 1)!important;
            }
        }
        /deep/.el-pagination__jump{
            height: 46px;
            line-height: 46px;
            font-size: 16px;
            font-weight: 500;
            columns: rgba(0, 0, 0, 1);
            .el-pagination__editor{
                height: 46px;
                .el-input__inner{
                    height: 46px;
                }
            } 
        }
    }
}
</style>